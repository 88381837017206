import {
  ApprovalState,
  Comment,
  DueDiligenceState,
  EdgeStory,
  EdgeStoryEntity,
  EdgeStoryObject,
  EdgeStoryObjectStyle,
  Event,
  HeaderInfo,
  LibraryContentType,
  LibraryEntry,
  Post,
  User,
  commentConverter,
  edgeStoryDataConverter,
  eventConverter,
  libraryEntryConverter,
  postConverter,
  userConverter,
} from "@sequoiacap/shared/models";
import { CloudFunctionName, callCloudFunction } from "./firebase/FirebaseAPI";
import { Hit, PaginatedSearchResult, SearchResult } from "./algolia/types";
import { QueryOptions, useAlgoliaSearch } from "./algolia/useAlgoliaSearch";
import { StorageTimestamp } from "./firebase/firebase-data-converter";
import { asEnum } from "@sequoiacap/shared/utils/enum";
import { mutateDocument } from "./swr-firebase";
import { parseJSON } from "@sequoiacap/shared/utils/superjson";
import { processAlgoliaDocument } from "./algolia/useAlgolia";
import { trackSearchTiming } from "~/utils/analytics";
import { useAPIGetServerProp } from "./user-api";
import { useCallback, useEffect, useRef } from "react";
import { useGetLibraryAudienceKey } from "./library-api";
import useLightUserInfo from "./useLightUserInfo";
import useSWR, { mutate as staticMutate } from "swr";

type SearchToken = {
  search_key: string;
  app_id: string;
  prefix: string;
};

export function useSearchToken(userId: string | null): {
  token?: SearchToken | null;
  refreshToken: () => void;
  error: Error | null;
  loading: boolean;
} {
  const {
    data: token,
    error,
    mutate,
  } = useSWR(
    CloudFunctionName.getSearchToken,
    async () => {
      if (userId) {
        const start = new Date();
        const searchToken = await callCloudFunction<SearchToken>(
          CloudFunctionName.getSearchToken,
          {},
        );

        const trackProperties = {
          step: "get_token",
          ms: new Date().getTime() - start.getTime(),
        };
        trackSearchTiming(trackProperties);
        return searchToken;
      } else {
        return null;
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 60 * 60 * 1000,
    },
  );
  if (token === undefined) {
    return {
      error,
      loading: !error,
      refreshToken: mutate,
    };
  }

  return {
    token,
    error,
    loading: false,
    refreshToken: mutate,
  };
}

export type LibrarySearchResult = SearchResult<
  LibraryEntry,
  { title: string; body: string }
>;

export type UserSearchResultHighlights = {
  name?: string;
  title?: string;
  company_name?: string;
  background?: string;
  investment_stage?: string;
  company_tagline?: string;
};

export type UserSearchResult = SearchResult<User, UserSearchResultHighlights>;

export type HashtagSearchResult = SearchResult<
  { tag: string },
  { tag: string }
>;

export type PostOrComment = Post | Comment;

export type ConversationSearchResult = SearchResult<
  PostOrComment,
  { title: string; body: string }
>;

export type ConversationSearchResultData = {
  type: "post" | "comment";
  postId: string; // Always there, either the current post, or the comments parent post id.
  commentId?: string; // Only set if type == comment
  postTitle?: string; // escaped when type==post
  createdByName?: string; // escaped (can be used dangerously)
  postBody?: string;
  postCreatedById: string;
  postCreatedByName?: string; // not escaped
  commentCreatedById?: string;
  postCreatedAt?: Date;
  commentCreatedAt?: Date;
  commentCreatedByName?: string; // not escaped
  commentBody?: string;
  groupName?: string;
  groupId?: string;
  isMemo?: boolean;
  approvalState?: ApprovalState;
  dueDiligenceState?: DueDiligenceState;
  approvedByUserId?: string;
  approvedByUserName?: string;
  memoMeta?: {
    companyUrl?: string;
    cofounderLinkedIn?: string[];
    securedLeadInvestor?: string;
  };
};

/**
 * Fields in Algolia that may have snippets or highlights.
 *
 * The highlights are HTML (e.g. <em>hit</em>) so they need to be render with dangerouslySetInnerHTML.
 * This means the indexer must escape these fields.
 */
export type ConversationSearchResultHit = {
  /** Title for post hits. Comments don't have a title */
  title: string;
  /** If comment, it's the comment's creator; if post, it's the post's creator */
  created_by_name?: string;
  group_name?: string;

  /** If comment, it's the comment's post's title; if post, empty */
  post_title: string;
  /** If comment, it's the comment's post's creator; if post, empty */
  post_created_by_name?: string;

  body: string;
};

export type ConversationSearchResultById = SearchResult<
  ConversationSearchResultData,
  ConversationSearchResultHit
>;

export function useSearchConversationByUser(
  userId: string,
  limit: number,
): PaginatedSearchResult<ConversationSearchResult> {
  const index = `conversation_created_at_desc`;

  const queryOption: QueryOptions = {
    query: "",
    attributesToRetrieve: ["id", "post_id"],
    attributesToHighlight: [],
    attributesToSnippet: [],
    hitsPerPage: limit,
    filters: `created_by_id:${userId}`,
  };

  const result = useAlgoliaSearch<
    PostOrComment,
    { title: string; body: string }
  >(index, queryOption, processConversationResult);

  return result;
}

export function useSearchConversationByHashtag(
  hashtag: string,
  limit: number,
): PaginatedSearchResult<ConversationSearchResult> {
  const index = `conversation_created_at_desc`;

  const queryOption: QueryOptions = {
    query: "",
    attributesToRetrieve: ["id", "post_id"],
    attributesToHighlight: [],
    attributesToSnippet: [],
    hitsPerPage: limit,
    filters: `hashtag:${hashtag}`,
  };

  const result = useAlgoliaSearch<
    PostOrComment,
    { title: string; body: string }
  >(index, queryOption, processConversationResult);
  return result;
}

export function useSearchGetLatestComments(
  postId: string | undefined,
  limit = 2,
): PaginatedSearchResult<SearchResult<Comment>> {
  const index = `conversation_created_at_desc`;
  const queryOption: QueryOptions = {
    query: "",
    attributesToRetrieve: ["id", "post_id", "parent_id", "type", "raw_data"],
    attributesToHighlight: [],
    attributesToSnippet: [],
    hitsPerPage: limit,
    filters: `post_id:${postId} AND type:comment`,
  };

  const result = useAlgoliaSearch<Comment, { title: string; body: string }>(
    postId ? index : null,
    queryOption,
    processConversationCommentResult,
  );

  return result;
}

export function useSearchGetPostComments(
  postId: string | undefined,
  limit = 2,
): PaginatedSearchResult<SearchResult<Comment>> {
  const index = `conversation_created_at_desc`;
  const queryOption: QueryOptions = {
    query: "",
    attributesToRetrieve: ["id", "post_id", "parent_id", "type", "raw_data"],
    attributesToHighlight: [],
    attributesToSnippet: [],
    hitsPerPage: limit,
    filters: `parent_id:"post:${postId}" AND type:comment`,
  };

  const result = useAlgoliaSearch<Comment, { title: string; body: string }>(
    postId ? index : null,
    queryOption,
    processConversationCommentResult,
  );

  return result;
}

export function useSearchGetCommentReplies(
  commentId: string | undefined,
): PaginatedSearchResult<SearchResult<Comment>> {
  const index = `conversation_created_at_desc`;
  const queryOption: QueryOptions = {
    query: "",
    attributesToRetrieve: ["id", "post_id", "parent_id", "type", "raw_data"],
    attributesToHighlight: [],
    attributesToSnippet: [],
    filters: `parent_id:"comment:${commentId}" AND type:comment`,
  };

  const result = useAlgoliaSearch<Comment, { title: string; body: string }>(
    commentId ? index : null,
    queryOption,
    processConversationCommentResult,
  );

  return result;
}

export function useSearchConversation(
  query: string | undefined,
  limit: number,
): PaginatedSearchResult<ConversationSearchResultById> {
  const index = `conversation`;

  const queryOption: QueryOptions = {
    query,
    attributesToRetrieve: [
      "id",
      "post_id",
      "title",
      "body",
      "created_at",
      "created_by_id",
      "post_created_by_name",
      "post_created_by_id",
      "post_title",
      "post_created_at",
      "group_id",
      "group_name",
      "is_memo",
      "comment_created_by_name",
      "memo_approval_state",
      "memo_due_dilligence_state",
      "memo_approved_by_id",
      "memo_approved_by_name",
      "memo_meta",
      "raw_data",
    ],
    attributesToHighlight: ["title", "created_by_name", "group_name"],
    attributesToSnippet: ["body"],
    hitsPerPage: limit,
  };

  const result = useAlgoliaSearch<
    ConversationSearchResultData,
    ConversationSearchResultHit
  >(query ? index : null, queryOption, processConversationResultId);
  return result;
}

export function useSearchGetFeed(
  ready: boolean,
  limit: number,
): PaginatedSearchResult<Post> {
  const index = ready ? "conversation" : null;
  const queryOption: QueryOptions = {
    attributesToRetrieve: ["id"],
    attributesToHighlight: [],
    attributesToSnippet: [],
    hitsPerPage: limit,
    filters: `type:post`,
  };

  const { data, ...rest } = useAlgoliaSearch<
    Post,
    { title: string; body: string }
  >(index, queryOption, processPostResult);

  return {
    data: data?.map((entry) => entry.data),
    ...rest,
  };
}

export function useSearchGetGroupFeed(
  groupId: string,
  limit = 30,
  filterString = "",
): PaginatedSearchResult<Post> {
  const index = groupId ? "conversation_group" : null;
  let filters = `group_id:${groupId} AND type:post`;

  if (filterString) {
    filters += ` AND ${filterString}`;
  }

  const queryOption: QueryOptions = {
    attributesToRetrieve: ["id"],
    attributesToHighlight: [],
    attributesToSnippet: [],
    hitsPerPage: limit,
    filters,
  };

  const { data, ...rest } = useAlgoliaSearch<
    Post,
    { title: string; body: string }
  >(index, queryOption, processPostResult);

  return {
    data: data?.map((entry) => entry.data),
    ...rest,
  };
}

export function useSearchPostsByUserV2(
  userId: string,
  limit: number,
): PaginatedSearchResult<FeedItemSearchResult> {
  const { loading, processor } = useProcessFeedResult();
  const index = userId && !loading ? `feed` : null;
  const queryOption: QueryOptions = {
    query: "",
    attributesToRetrieve: ["id", "type", "sort_order_at"],
    attributesToHighlight: [],
    attributesToSnippet: [],
    hitsPerPage: limit,
    filters: `created_by_id:${userId} AND type:post`,
  };

  const result = useAlgoliaSearch<FeedItem, { title: string; body: string }>(
    index,
    queryOption,
    processor,
  );

  return result;
}

const processPostResult = async (hit: Hit): Promise<Post | null> => {
  if (typeof hit.id !== "string") {
    return null;
  }
  const path = `post/${hit.id}`;
  try {
    return (await mutateDocument(path, postConverter)) ?? null;
  } catch (e) {
    console.error("Can't fetch post" + path, e);
    return null;
  }
};

const processConversationResult = async (
  hit: Hit,
): Promise<PostOrComment | null> => {
  if (typeof hit.id !== "string") {
    return null;
  }
  try {
    if (typeof hit.post_id === "string") {
      const postPath = `post/${hit.post_id}`;
      const post = await mutateDocument(postPath, postConverter);
      if (!post) {
        return null;
      }
      const path = `post/${hit.post_id}/comment/${hit.id}`;
      return (await mutateDocument(path, commentConverter)) ?? null;
    } else {
      const path = `post/${hit.id}`;
      return (await mutateDocument(path, postConverter)) ?? null;
    }
  } catch (e) {
    console.error("Can't fetch processConversationResult", hit, e);
    return null;
  }
};

const processConversationCommentResult = async (
  hit: Hit,
): Promise<Comment | null> => {
  if (hit.type !== "comment") {
    return null;
  }
  try {
    if (typeof hit.raw_data === "string") {
      const comment = parseJSON<Comment>(hit.raw_data);
      const path = `post/${comment.postId}/comment/${comment.id}`;
      const doc = await processAlgoliaDocument(comment.id, path, comment);
      await staticMutate(path, doc, false);
      return comment;
    }
  } catch (e) {
    console.error("Can't fetch processConversationCommentResult", hit, e);
  }
  return null;
};

const processConversationResultId = async (
  hit: Hit,
): Promise<ConversationSearchResultData | null> => {
  if (typeof hit.id !== "string") {
    return null;
  }
  try {
    if (typeof hit.post_id === "string") {
      return {
        type: "comment" as "post" | "comment",
        postId: hit.post_id,
        commentId: hit.id,
        createdByName: hit.created_by_name as string | undefined,
        postTitle: hit.post_title as string | undefined,
        postCreatedById: hit.post_created_by_id as string,
        postCreatedByName: hit.post_created_by_name as string | undefined,
        commentCreatedById: hit.created_by_id as string,
        commentCreatedByName: hit.comment_created_by_name as string | undefined,
        postCreatedAt: hit.post_created_at
          ? new Date(hit.post_created_at as number)
          : undefined,
        commentCreatedAt: hit.created_at
          ? new Date(hit.created_at as number)
          : undefined,
        commentBody: hit.body as string | undefined,
        groupName: hit.group_name as string | undefined,
        groupId: hit.group_id as string | undefined,
      };
    } else {
      return {
        type: "post" as "post" | "comment",
        createdByName: hit.created_by_name as string | undefined,
        postId: hit.id,
        postTitle: hit.title as string | undefined,
        postBody: hit.body as string | undefined,
        postCreatedById: hit.created_by_id as string,
        postCreatedByName: hit.post_created_by_name as string | undefined,
        postCreatedAt: new Date(hit.created_at as number),
        groupName: hit.group_name as string | undefined,
        groupId: hit.group_id as string | undefined,
        isMemo: hit.is_memo as boolean | undefined,
        approvalState: hit.memo_approval_state as ApprovalState | undefined,
        dueDiligenceState: hit.memo_due_dilligence_state as
          | DueDiligenceState
          | undefined,
        approvedByUserId: hit.approved_by_id as string | undefined,
        approvedByUserName: hit.approved_by_name as string | undefined,
        memoMeta: parseJSON<{
          companyUrl?: string;
          cofounderLinkedIn?: string[];
          securedLeadInvestor?: string;
        }>((hit.memo_meta as string) ?? "{}"),
      };
    }
  } catch (e) {
    console.error("Can't fetch processConversationResultId", hit, e);
    return null;
  }
};

export enum PeopleSearchSortOption {
  alphabetical = "alphabetical",
  recentActivity = "recentActivity",
  createdAt = "createdAt",
}

export function useSearchUser(
  query: string | undefined,
  limit: number,
  sortedBy: PeopleSearchSortOption = PeopleSearchSortOption.recentActivity,
  filterString?: string,
  selectedFilters?: Record<string, string[]>,
  disjunctiveFacets = ["sector", "structured_location"],
): PaginatedSearchResult<UserSearchResult> {
  const { isWritableUser } = useLightUserInfo();

  let index: string | null;
  if (query === undefined || !isWritableUser) {
    index = null;
  } else if (sortedBy === PeopleSearchSortOption.alphabetical) {
    index = "user_v2_name_asc";
  } else if (sortedBy === PeopleSearchSortOption.createdAt) {
    index = "user_v2_created_at_desc";
  } else {
    index = "user_v2";
  }

  const queryOption: QueryOptions = {
    query,
    attributesToRetrieve: [
      "id",
      "raw_data",
      "name",
      "title",
      "company_name",
      "company_tagline",
    ],
    attributesToHighlight: ["name", "title", "company_name", "company_tagline"],
    hitsPerPage: limit,
    filters: filterString,
    disjunctiveFacets,
    disjunctiveFacetsRefinements: selectedFilters,
  };

  const result = useAlgoliaSearch<
    User,
    { name: string; title: string; company_name: string }
  >(index, queryOption, processUserResult);
  console.log(
    `useSearchUser/query=${query}, filterString=${filterString} index=${index}/result=${result.data?.length}`,
  );
  return result;
}

export function useSearchMention(
  query: string | undefined,

  /** groupId you are mentioning within. Depending on the query and the current group, the order of the results will change (e.g. users within the group show higher than others) */
  groupContext: string | undefined,
  limit: number,
): PaginatedSearchResult<UserSearchResult> {
  const { isWritableUser } = useLightUserInfo();

  let index: string | null;
  if (query === undefined || !isWritableUser) {
    index = null;
  } else {
    index = "user_v2_name_asc";
  }

  let filters: string | undefined = undefined;
  const optionalFilters: string[] = [];
  if (groupContext) {
    if (!query?.length) {
      filters = `group_ids:${groupContext}`;
    } else {
      optionalFilters.push(`group_ids:${groupContext}`);
    }
  }
  const queryOption: QueryOptions = {
    query,
    filters,
    optionalFilters,
    restrictSearchableAttributes: ["name"],
    attributesToRetrieve: ["id"],
    hitsPerPage: limit,
  };

  const result = useAlgoliaSearch<
    User,
    { name: string; title: string; company_name: string }
  >(index, queryOption, processUserResult);

  return result;
}

const processUserResult = async (hit: Hit): Promise<User | null> => {
  if (typeof hit.id !== "string") {
    return null;
  }
  const path = `user/${hit.id}`;
  if (typeof hit.raw_data === "string") {
    const user = parseJSON<User>(hit.raw_data);
    const doc = await processAlgoliaDocument(user.id, path, user);
    await staticMutate(path, doc, false);
    return user;
  }

  try {
    return (await mutateDocument(path, userConverter)) ?? null;
  } catch (e) {
    console.error("Can't fetch user" + path, e);
    return null;
  }
};

export function useSearchLibrary(
  query: string | undefined,
  limit: number,
  filterString?: string,
): PaginatedSearchResult<LibrarySearchResult> {
  const { key } = useGetLibraryAudienceKey();
  const index = key ? `library_${key}` : null;
  const queryOption: QueryOptions = {
    query,
    attributesToRetrieve: [
      "id",
      "content_type",
      "created_at",
      "title",
      "short_description",
    ],
    attributesToHighlight: ["title"],
    attributesToSnippet: ["body:30"],
    hitsPerPage: limit,
    facets: ["*"],
    filters: filterString,
  };

  const processLibraryResult = useCallback(
    async (hit: Hit): Promise<LibraryEntry | null> => {
      if (typeof hit.id !== "string") {
        return null;
      }

      const contentType = asEnum(
        LibraryContentType,
        hit.content_type as string | undefined,
        LibraryContentType.unknown,
      );
      if (
        typeof hit.title === "string" &&
        typeof hit.created_at === "number" &&
        contentType !== LibraryContentType.unknown
      ) {
        return new LibraryEntry(
          hit.id,
          hit.title,
          "",
          contentType,
          new Date(hit.created_at),
          undefined,
          undefined,
          undefined,
          hit.short_description as string | undefined,
        );
      }
      const path = `library/${hit.id}/v5/${key}`;
      try {
        console.log("useSearchLibrary/processLibraryResult", path);
        return (await mutateDocument(path, libraryEntryConverter)) ?? null;
      } catch (e) {
        console.error("Can't fetch library" + path, e);
        return null;
      }
    },
    [key],
  );

  const result = useAlgoliaSearch<
    LibraryEntry,
    { title: string; body: string }
  >(query ? index : null, queryOption, processLibraryResult);
  return result;
}

export function useSearchLibraryFacets() {
  const { key } = useGetLibraryAudienceKey();
  const index = key ? `library_${key}` : null;
  const queryOption: QueryOptions = {
    disjunctiveFacets: ["topic_hierarchy.lvl1", "topic_hierarchy.lvl2"],
    hitsPerPage: 0,
  };
  const processLibraryResult = async (): Promise<void> => {
    // no-op
  };
  return useAlgoliaSearch<void>(index, queryOption, processLibraryResult);
}

export function useSearchHashtag(
  query: string | undefined,
): PaginatedSearchResult<HashtagSearchResult> {
  const index = `hashtag`;
  const queryOption: QueryOptions = {
    query,
    attributesToRetrieve: ["tag"],
  };
  return useAlgoliaSearch<{ tag: string }, { tag: string }>(
    index,
    queryOption,
    processHashtagResult,
  );
}

const processHashtagResult = async (
  hit: Hit,
): Promise<{ tag: string } | null> => {
  if (typeof hit.tag !== "string") {
    return null;
  }
  return {
    tag: hit.tag,
  };
};

export type PostOrEventOrLibary = Post | Event | LibraryEntry;
export type PostOrEventOrLibaryOrComment = PostOrEventOrLibary | Comment;
export type FeedSearchResult = SearchResult<PostOrEventOrLibary>;

export type FeedItemType =
  | Post
  | Comment
  | LibraryEntry
  | Event
  | User
  | EdgeStory;

export enum ReasonType {
  newEvent = "new_event",
  newReply = "new_reply",
  newPost = "new_post",
  upcomingEvent = "upcoming_event",
  newLibraryPage = "new_library_page",
  searchResult = "search_result",
  edgeStory = "edge_story",
}

export type FeedItem = {
  id: string;
  reasonType?: ReasonType;
  sortOrderAt?: StorageTimestamp;
  entity: FeedItemType;
};

export type FeedItemSearchResult = SearchResult<FeedItem>;

export function useSearchFeedFacets() {
  const { isWritableUser } = useLightUserInfo();
  const { loading, processor } = useProcessFeedResult();
  const index = isWritableUser && !loading ? "feed" : null;
  const queryOption: QueryOptions = {
    disjunctiveFacets: ["topic_hierarchy.lvl1", "topic_hierarchy.lvl2"],
    hitsPerPage: 0,
  };
  return useAlgoliaSearch<FeedItem>(index, queryOption, processor);
}

function useProcessFeedResult(): {
  loading: boolean;
  processor: (hit: Hit) => Promise<FeedItem | null>;
} {
  const { key } = useGetLibraryAudienceKey();
  const { userId: loggedInUserId } = useLightUserInfo();
  const { data: serverProps } = useAPIGetServerProp(loggedInUserId);

  // use the ref to keep the key value when the processor is called,
  // useSWR's fetcher only takes the first instance of the processor
  // can't change the processor after the first call
  const keyRef = useRef(key);
  useEffect(() => {
    keyRef.current = key;
  }, [key]);
  const serverPropsRef = useRef(serverProps);
  useEffect(() => {
    serverPropsRef.current = serverProps;
  }, [serverProps]);

  const processor = useCallback(async (hit: Hit): Promise<FeedItem | null> => {
    if (typeof hit.id !== "string") {
      return null;
    }
    try {
      const sortOrderAt = new Date(hit.sort_order_at as number);
      if (hit.type === "event") {
        const path = `event/${hit.id}`;
        const event = await mutateDocument(path, eventConverter);
        if (event) {
          return {
            entity: event,
            id: hit.id,
            reasonType:
              event.createdAt && sortOrderAt > event.createdAt
                ? ReasonType.upcomingEvent
                : ReasonType.newEvent,
            sortOrderAt,
          };
        }
      } else if (hit.type === "library") {
        if (!keyRef.current) {
          return null;
        }
        const path = `library/${hit.id}/v5/${keyRef.current}`;
        const library = await mutateDocument(path, libraryEntryConverter);
        if (library) {
          return {
            entity: library,
            id: hit.id,
            reasonType: ReasonType.newLibraryPage,
            sortOrderAt: new Date(hit.sort_order_at as number),
          };
        }
      } else if (hit.type === "post") {
        const path = `post/${hit.id}`;
        const post = await mutateDocument(path, postConverter);
        if (post) {
          return {
            entity: post,
            id: hit.id,
            reasonType:
              (post.numComments ?? 0) > 0
                ? ReasonType.newReply
                : ReasonType.newPost,
            sortOrderAt: new Date(hit.sort_order_at as number),
          };
        }
      } else if (hit.type === "edge_story") {
        if (!serverPropsRef.current?.showEdgeStory2) {
          return null;
        }
        const path = `edge_story/${hit.id}`;
        let edgeStory: EdgeStory | undefined;
        if (typeof hit.raw_data === "string") {
          edgeStory = parseJSON<EdgeStory>(hit.raw_data);
          const doc = await processAlgoliaDocument(
            edgeStory.id,
            path,
            edgeStory,
          );
          await staticMutate(path, doc, false);
        }
        if (!edgeStory) {
          edgeStory = await mutateDocument(path, edgeStoryDataConverter);
        }
        if (edgeStory) {
          return {
            entity: edgeStory,
            id: hit.id,
            reasonType: ReasonType.edgeStory,
            sortOrderAt: new Date(hit.sort_order_at as number),
          };
        }
      }
      return null;
    } catch (e) {
      console.error("Can't fetch processFeedResult", hit, e);
      return null;
    }
  }, []);

  return { loading: !key, processor };
}

function combineFeedItems(a: FeedItem, b: FeedItem): FeedItem | undefined {
  if (a.reasonType !== b.reasonType) {
    return undefined;
  }
  let listOfEntities: EdgeStoryEntity[] = [];
  if (a.entity instanceof EdgeStory) {
    listOfEntities = a.entity.listOfEntities ?? [];
  } else if (a.entity instanceof LibraryEntry) {
    listOfEntities.push(a.entity);
  } else if (a.entity instanceof Event) {
    listOfEntities.push(a.entity);
  } else {
    console.error(`combineFeedItems: unknown entity type`, a.entity, b.entity);
    return undefined;
  }

  const isLibraryListStory = listOfEntities[0] instanceof LibraryEntry;
  const isEventListStory = listOfEntities[0] instanceof Event;

  if (b.entity instanceof LibraryEntry && isLibraryListStory) {
    listOfEntities.push(b.entity);
  } else if (b.entity instanceof Event && isEventListStory) {
    listOfEntities.push(b.entity);
  } else {
    console.error(`combineFeedItems: unknown entity type`, a.entity, b.entity);
    return undefined;
  }

  // TODO(Kurt) fill in the story
  const headerInfo = new HeaderInfo(
    `<strong>${listOfEntities.length} ${isLibraryListStory ? "articles" : "events"}</strong>`,
    undefined,
    undefined,
    [isLibraryListStory ? "/resources/latest" : "/event"],
    b.sortOrderAt,
  );

  const edgeStory = new EdgeStory(
    b.id,
    b.entity.createdAt ?? new Date(),
    b.entity.updatedAt ?? new Date(),
    "",
    {},
    1,
    headerInfo,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    listOfEntities.map((entity) => {
      if (entity instanceof LibraryEntry) {
        return new EdgeStoryObject(
          entity.author ?? "",
          EdgeStoryObjectStyle.imageOnRight,
          undefined,
          undefined,
          undefined,
          undefined,
          `/resources?story=${entity.id}`,
          entity.shortDescription,
          undefined,
          entity.featureImage,
        );
      } else if (entity instanceof Event) {
        return new EdgeStoryObject(
          entity.id,
          EdgeStoryObjectStyle.imageOnRight,
          undefined,
          undefined,
          undefined,
          undefined,
          `/event?event=${entity.id}`,
          entity.description,
          undefined,
          entity.imageUrl,
        );
      }
      return new EdgeStoryObject("");
    }),
  );
  edgeStory.listOfEntities = listOfEntities;
  return {
    id: b.id,
    reasonType: b.reasonType,
    sortOrderAt: b.sortOrderAt,
    entity: edgeStory,
  };
}

export function useSearchFeed(
  limit: number,
  selectedFilters?: Record<string, string[]>,
): PaginatedSearchResult<FeedItemSearchResult> {
  const { isWritableUser, userId: loggedInUserId } = useLightUserInfo();
  const { data: serverProps, loading: serverPropLoading } =
    useAPIGetServerProp(loggedInUserId);
  const { loading, processor } = useProcessFeedResult();
  const index =
    isWritableUser && !loading && !serverPropLoading ? "feed" : null;
  const queryOption: QueryOptions = {
    attributesToRetrieve: [
      "id",
      "type",
      "sort_order_at",
      "collapse_key",
      "raw_data",
    ],
    disjunctiveFacets: ["topic_hierarchy.lvl1", "topic_hierarchy.lvl2"],
    hitsPerPage: limit,
  };
  if (selectedFilters) {
    queryOption.disjunctiveFacetsRefinements = selectedFilters;
  }
  return useAlgoliaSearch<FeedItem>(
    index,
    queryOption,
    processor,
    serverProps?.collapseFeed ? combineFeedItems : undefined,
  );
}
