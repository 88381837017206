import { User, UserAudience, UserList } from "@sequoiacap/shared/models";
import { createContext, useContext } from "react";
import {
  isWritableClaim,
  landingPageClaim,
  useAuth,
} from "~/components/auth/useAuth";
import { useAPIGetMyNormalGroupIds } from "./group-api";
import { useAPIGetUser } from "./user-api";
import { useAPIGetUserLists } from "./user-list-api";
import { useTrackUserInfo } from "~/utils/analytics";
import useMemoCompare from "~/hooks/useMemoCompare";

export interface UserInfoContextType {
  userId?: string;
  displayName?: string;
  email?: string;
  specialGroupIds?: string[];
  audiences?: UserAudience[];
  writableSpecialGroupIds?: string[];
  normalGroupIds?: string[];
  isWritableUser?: boolean;
  isAdmin?: boolean;
  isGeneratedAccount?: boolean;
  landingPage?: string;
  loading: boolean;
  userRecord?: User;
  userLists?: UserList[];
  error?: Error;
}

const UserInfoContext = createContext<UserInfoContextType | null>(null);

export default function useUserInfo(): UserInfoContextType {
  const context = useContext(UserInfoContext);
  if (!context) {
    throw new Error("useUserInfo must be used within a UserInfoProvider");
  }
  return context;
}

export const UserInfoProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { authUser, loading: authLoading } = useAuth();
  const { data: user, loading: userLoading } = useAPIGetUser(authUser?.id, {
    listen: true,
  });
  const isWritableUser = isWritableClaim(authUser?.claims);
  const { data: groupIds, loading: groupIdsLoading } =
    useAPIGetMyNormalGroupIds(isWritableUser ? authUser?.id : undefined);
  const { data: userLists, loading: userListsLoading } = useAPIGetUserLists(
    authUser?.id,
  );
  const landingPage = landingPageClaim(authUser?.claims);
  const specialGroupIds = useMemoCompare(
    [
      ...Array.from(
        new Set<string>([
          ...(user?.specialGroup ?? []),
          ...((authUser?.claims?.sg as string[]) ?? []),
        ]),
      ),
    ].sort(),
  );

  const newContext = () => {
    if (userLoading || groupIdsLoading || userListsLoading || authLoading) {
      return {
        loading: true,
      };
    }
    if (!authUser?.id) {
      return {
        loading: false,
      };
    }
    const isAdmin = !!authUser?.claims?.admin;
    const audiences =
      user?.audience ??
      (isAdmin
        ? [
            UserAudience.founder,
            UserAudience.scout,
            UserAudience.leader,
            UserAudience.sequoia,
            UserAudience.talent,
          ]
        : undefined);

    return {
      userId: authUser?.id,
      displayName: user?.name ?? authUser?.displayName ?? undefined,
      email: authUser?.email ?? undefined,
      specialGroupIds,
      writableSpecialGroupIds: user?.specialGroup,
      normalGroupIds: groupIds ?? [],
      isWritableUser,
      isAdmin,
      landingPage,
      userRecord: user,
      loading: false,
      userLists,
      audiences,
      isGeneratedAccount: !!authUser?.claims?.autocreated,
    };
  };

  const context = useMemoCompare<UserInfoContextType>(newContext());

  useTrackUserInfo(context);

  return (
    <UserInfoContext.Provider value={context}>
      {children}
    </UserInfoContext.Provider>
  );
};
